import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewUI from 'view-design';
Vue.use(ViewUI);

//解决路由重复点击
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const Login = () =>
  import('../views/login/index.vue')
const register = () =>
  import('../views/register/index.vue')

const password = () =>
  import('@/views/password/index.vue')

const display = () =>
  import('../views/display.vue') //展示 

const homeTopic = () =>
  import('../views/homeTopic/index.vue')

const my = () =>
  import('../views/my/index.vue')

const look = () =>
  import('../views/my/look.vue')

const certification = () =>
  import('../views/certification/index.vue')


const questionnaire = () =>
  import('@/views/questionnaire/index.vue')

// const questionnaireDel = () =>
//   import('@/views/questionnaire/questionnaireDel.vue') 

const CARinlet = () =>
  import('@/views/CARinlet/index.vue')

const CARprocedure = () =>
  import('@/views/CARprocedure/index.vue')

const casecompile = () =>
  import('@/views/case/compile.vue')

const casedetails = () =>
  import('@/views/case/details.vue')

const routes = [{
  path: "/",
  redirect: "/homeTopic",
},
{
  path: '/display',
  name: 'display',
  component: display,
  children: [
    {
      path: '/homeTopic',
      name: 'homeTopic',
      component: homeTopic,
    },
    {
      path: '/my',
      name: 'my',
      component: my,
    },
    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: questionnaire,
    },
    {
      path: '/look',
      name: 'look',
      component: look,
    },
    // {
    //   path: '/questionnaireDel', 
    //   name: 'questionnaireDel',
    //   component: questionnaireDel,
    // },
    {
      path: '/CARinlet',
      name: 'CARinlet',
      component: CARinlet,
    },
    {
      path: '/CARprocedure',
      name: 'CARprocedure',
      component: CARprocedure,
    },
    {
      path: '/casecompile',
      name: 'casecompile',
      component: casecompile,
    },
    {
      path: '/casedetails',
      name: 'casedetails',
      component: casedetails,
    },
  ]
},
{
  path: '/login',
  name: 'login',
  component: Login
},
{
  path: '/register',
  name: 'register',
  component: register
},
{
  path: '/password',
  name: 'password',
  component: password
},
//认证 
{
  path: '/certification',
  name: 'certification',
  component: certification,
},
]


Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

//* 配置加载进度条样式
ViewUI.LoadingBar.config({
  color: '#9D499F',
  height: 4
});

// 路由 
router.beforeEach((to, form, next) => {
  // ViewUI.LoadingBar.finish(); //加载进度条      
  if (to.path == '/my' || to.path == '/CARprocedure' || to.path == '/CARinlet' || to.path == '/questionnaire') {
    if (!localStorage.getItem('token')) {
      router.push('/login');
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router